<template>
  <b-container class="bg-light page-wrapper sandbox-report-filter">
    <div class="main-content-wrapper">
      <b-row no-gutters>
        <b-col> </b-col>
      </b-row>
      <b-row no-gutters>
        <b-col>
          <h1 class="text-left mb-0">Report filter demo</h1>
        </b-col>
      </b-row>
      <b-row no-gutters>
        <p>Try to use buttons to hide/show filter. On clicking "Hide filter" component is being destroyed, so you can test if filter selections returns back on next clicking "Show filter". Pay attention that selections are saved only after component emitted data. So if you selected items and did not apply filter - selections will not be saved.</p>
        <p>Important! Filter state with selections should be saved in parent component. It is a way how we can restore state after destroying filter component. When filter applied or initial api requests are finished filter emits state to parent component. Parent component is responsible for saving emitted state. This state can be passed to filter as component property "filterData".</p>
        <b-button @click="isFilterShown = false">Hide filter</b-button>
        <b-button class="ml-2" @click="isFilterShown = true">Show filter</b-button>
        <b-col cols="12">
          <report-filter
            v-if="isFilterShown"
            :filter-data="reportFilterData"
            :disabled="['income', 'liability', 'person', 'asset', 'expense']"
            @filter-applied="onFilterApplied"
            @filter-data-updated="mergeFilterPartials"
          />
        </b-col>
      </b-row>
      <b-row no-gutters>
        <b-col>
          <h4 class="mt-5">Emitted request string:</h4>
          <pre>{{ filterRequestString }}</pre>
          <h4 class="mt-5">Emitted filter data:</h4>
          <pre>{{ reportFilterData }}</pre>
        </b-col>
      </b-row>
    </div>
  </b-container>
</template>

<script>
import ReportFilter from '@/components/common/ReportFilter.vue'

export default {
  name: 'PeriodSelectorExample',
  components: { ReportFilter },
  data () {
    return {
      reportFilterData: null,
      filterRequestString: null,
      isFilterShown: true
    }
  },
  methods: {
    onFilterApplied (eventData) {
      this.filterRequestString = eventData
    },
    mergeFilterPartials (partialData) {
      if (!this.reportFilterData) {
        this.reportFilterData = null
      }
      this.reportFilterData = Object.assign({}, this.reportFilterData, partialData)
    }
  }
}
</script>

<style lang="scss">
.sandbox-report-filter {
  pre {
    width: 100%;
    max-height: 600px;
    overflow: scroll;
    font-size: 10px;
  }
}
</style>
